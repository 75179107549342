//== Buttons
//
//## For each of Bootstrap's buttons, define text, background and border color.

$sans-serif-family: 'SF Pro Display', 'Helvetica Neue', Arial, sans-serif !default;

$none: 0 !default;
$border-thin: 1px !default;
$border-thick: 2px !default;

$white-color: #ffffff !default;
$white-bg: #ffffff !default;
$orange-bg: #e95e38 !default;

$smoke-bg: #f5f5f5 !default;
$light-black: #444 !default;

$black-bg: rgba(30, 30, 30, 0.97) !default;

$black-color: #171717 !default;
$black-states-color: #333333 !default;
$black-hr: #444444 !default;

$medium-gray: #696969 !default;
$dark-gray: #383838 !default;

$opacity-gray-3: rgba(222, 222, 222, 0.3) !default;
$opacity-gray-5: rgba(222, 222, 222, 0.5) !default;
$opacity-gray-8: rgba(222, 222, 222, 0.8) !default;

$opacity-5: rgba(255, 255, 255, 0.5) !default;
$opacity-8: rgba(255, 255, 255, 0.8) !default;

$datepicker-color-days: rgba(255, 255, 255, 0.8) !default;
$datepicker-color-old-new-days: rgba(255, 255, 255, 0.4) !default;

$opacity-1: rgba(255, 255, 255, 0.1) !default;
$opacity-2: rgba(255, 255, 255, 0.2) !default;

$opacity-mask: rgba(0, 0, 0, 0.4) !default;

$transparent-bg: transparent !default;
$dark-background: #555555 !default;

$default-color: #888888 !default;
$default-states-color: lighten($default-color, 6%) !default;
$default-color-opacity: rgba(182, 182, 182, 0.6) !default;

$primary-color: #f63a80 !default;
$light-gray: #eff0ef !default;
$primary-border-color: #f63a80 !default;
$primary-button-color: #f63a80 !default;
$primary-states-color: lighten($primary-color, 6%) !default;
$primary-color-opacity: rgba(249, 99, 50, 0.3) !default;
$primary-color-alert: rgba(249, 99, 50, 0.8) !default;

$success-color: #056b00 !default;
$success-states-color: lighten($success-color, 6%) !default;
$success-color-opacity: rgba(24, 206, 15, 0.3) !default;
$success-color-alert: rgba(6, 119, 0, 0.8) !default;

$bg-environment: #f63a80 !default;
$environment-color: #f63a80 !default;
$environment-states-color: darken($environment-color, 8%) !default;
$environment-states-color-light: lighten($environment-color, 8%) !default;
$environment-color-opacity: rgba($environment-color, 0.3) !default;
$environment-color-alert: rgb(202, 87, 67) !default;

$info-color: $primary-color !default;
$info-states-color: lighten($info-color, 6%) !default;
$info-color-opacity: rgba($info-color, 0.3) !default;
$info-color-alert: rgba(44, 168, 255, 0.8) !default;

$warning-color: #ffb236 !default;
$warning-states-color: lighten($warning-color, 6%) !default;
$warning-color-opacity: rgba(255, 178, 54, 0.3) !default;
$warning-color-alert: rgba(255, 178, 54, 0.8) !default;

$danger-color: #e90057 !default;
$danger-states-color: lighten($danger-color, 6%) !default;
$danger-color-opacity: rgba(255, 54, 54, 0.3) !default;
$danger-color-alert: #d64022b2 !default;

/*     brand Colors              */
$brand-primary: $primary-color !default;
$brand-info: $info-color !default;
$brand-success: $success-color !default;
$brand-warning: $warning-color !default;
$brand-danger: $danger-color !default;
$brand-inverse: $black-color !default;

$link-disabled-color: #666666 !default;

/*      light colors         */
$light-blue: rgba($primary-color, 0.2);
$light-azure: rgba($info-color, 0.2);
$light-green: rgba($success-color, 0.2);
$light-orange: rgba($warning-color, 0.2);
$light-red: rgba($danger-color, 0.2);

//== Components
//

$padding-input-vertical: 14px !default;
$padding-input-horizontal: 18px !default;

$padding-btn-vertical: 12px !default;
$padding-btn-horizontal: 22px !default;

$padding-base-vertical: 0rem !default;
$padding-base-horizontal: 0.7rem !default;

$padding-round-horizontal: 23px !default;

$padding-simple-vertical: 10px !default;
$padding-simple-horizontal: 17px !default;

$padding-large-vertical: 9px !default;
$padding-large-horizontal: 48px !default;

$padding-small-vertical: 5px !default;
$padding-small-horizontal: 15px !default;

// $padding-xs-vertical:           1px !default;
// $padding-xs-horizontal:         5px !default;

$padding-label-vertical: 2px !default;
$padding-label-horizontal: 12px !default;

$margin-large-vertical: 30px !default;
$margin-base-vertical: 15px !default;
$margin-mini-vertical: 5px !default;

$margin-base-horizontal: 15px !default;

$margin-bottom: 10px !default;
$border: 1px solid !default;
$border-radius-extra-small: 0.125rem !default;
$border-radius-small: 0.2875rem !default;
$border-radius-large: 0.375rem !default;
$border-radius-extreme: 0.875rem !default;

$border-radius-large-top: $border-radius-large $border-radius-large 0 0 !default;
$border-radius-large-bottom: 0 0 $border-radius-large $border-radius-large !default;

$btn-round-radius: 25px !default;

$height-base: 55px !default;

$btn-icon-size: 3.5rem !default;
$btn-icon-size-regular: 2.375rem !default;
$btn-icon-font-size-regular: 0.9375rem !default;
$btn-icon-font-size-small: 0.6875rem !default;
$btn-icon-size-small: 1.875rem !default;
$btn-icon-font-size-lg: 1.325rem !default;
$btn-icon-size-lg: 3.6rem !default;

$font-size-h1: 3.5em !default; // ~ 49px
$font-size-h2: 2.5em !default; // ~ 35px
$font-size-h3: 1.825em !default; // ~ 28px
$font-size-h4: 1.5em !default; // ~ 24px
$font-size-h5: 1.1em !default; // ~ 22px
$font-size-h6: 1em !default; // ~ 12px

$font-size-h1-m: 1.6em !default; 
$font-size-h2-m: 1.4em !default; 
$font-size-h3-m: 1.2em !default; 
$font-size-h4-m: 1.0em !default; 
$font-size-h5-m: 0.8em !default; 
$font-size-h6-m: 0.8em !default;

$font-paragraph: 1.2em !default;
$font-size-navbar: 0.7em !default;
$font-size-mini: 0.7em !default;
$font-size-xsmall: 0.8em !default;
$font-size-small: 0.8em !default;
$font-size-base: 16px !default;
$font-size-large: 1em !default;
$font-size-large-navbar: 20px !default;
$font-size-blockquote: 1em !default; // ~ 15px

$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-semi: 600 !default;
$font-weight-bold: 700 !default;

$line-height-general: 1.5 !default;
$line-height-nav-link: 1.625rem !default;
$btn-icon-line-height: 2.4em !default;
$line-height: 1.35em !default;
$line-height-lg: 54px !default;

$border-radius-top: 10px 10px 0 0 !default;
$border-radius-bottom: 0 0 10px 10px !default;

$dropdown-shadow: 1px 2px 3px rgba(0, 0, 0, 0.125);
$box-shadow-raised: 0px 10px 25px 0px rgba(0, 0, 0, 0.3);

$general-transition-time: 300ms !default;

$slow-transition-time: 370ms !default;
$dropdown-coordinates: 29px -50px !default;

$fast-transition-time: 150ms !default;
$select-coordinates: 50% -40px !default;

$transition-linear: linear !default;
$transition-bezier: cubic-bezier(0.34, 1.61, 0.7, 1) !default;
$transition-ease: ease 0s;

//$navbar-padding-a:               9px 13px;
$navbar-margin-a: 15px 0px;

$padding-social-a: 10px 5px;

$navbar-margin-a-btn: 15px 0px;
$navbar-margin-a-btn-round: 16px 0px;

$navbar-padding-a-icons: 6px 15px;
$navbar-margin-a-icons: 6px 3px;

$navbar-padding-base: 0.625rem;
//$navbar-margin-brand:             5px  0px;

$navbar-margin-brand-icons: 12px auto;
$navbar-margin-btn: 15px 3px;

$height-icon-sm: 32px;
$width-icon-sm: 32px;
$padding-icon-sm: 4px;
$border-radius-icon-sm: 7px;

$height-icon-message: 40px;
$width-icon-message: 40px;

$height-icon-message-sm: 20px;
$width-icon-message-sm: 20px;

$topbar-x: topbar-x !default;
$topbar-back: topbar-back !default;
$bottombar-x: bottombar-x !default;
$bottombar-back: bottombar-back !default;

//Nucleo Icons

$nc-font-path: '~assets/fonts' !default;
$nc-font-size-base: 14px !default;
$nc-css-prefix: now-ui !default;
$nc-background-color: #eee !default;
$nc-li-width: (30em / 14) !default;
$nc-padding-width: (1em/3) !default;

// Social icons color
$social-twitter: #55acee !default;
$social-twitter-state-color: lighten(#55acee, 6%) !default;

$social-facebook: #3b5998 !default;
$social-facebook-state-color: lighten(#3b5998, 6%) !default;

$social-google: #dd4b39 !default;
$social-google-state-color: lighten(#dd4b39, 6%) !default;

$social-linkedin: #0077b5 !default;
$social-linkedin-state-color: lighten(#0077b5, 6%) !default;

$social-pinterest: #cc2127 !default;
$social-pinterest-state-color: lighten(#cc2127, 6%) !default;

$social-dribbble: #ea4c89 !default;
$social-dribbble-state-color: lighten(#ea4c89, 6%) !default;

$social-github: #333333 !default;
$social-github-state-color: lighten(#333333, 6%) !default;

$social-youtube: #e52d27 !default;
$social-youtube-state-color: lighten(#e52d27, 6%) !default;

$social-instagram: #125688 !default;
$social-instagram-state-color: lighten(#125688, 6%) !default;

$social-reddit: #ff4500 !default;
$social-reddit-state-color: lighten(#ff4500, 6%) !default;

$social-tumblr: #35465c !default;
$social-tumblr-state-color: lighten(#35465c, 6%) !default;

$social-behance: #1769ff !default;
$social-behance-state-color: lighten(#1769ff, 6%) !default;

$db-color-red: #730000 !default;
$db-color-orange: #ff553c !default;
$db-color-yellow: #b28b00 !default;
$db-color-blue: #005789 !default;
$db-color-green: #0d4200 !default;
$db-color-pink: #5a003a !default;
$db-color-dark: #121212 !default;
$db-color-light: #939393 !default;

$dropdown-shadow: 1px 2px 3px 0px rgba(0, 0, 0, 0.125);
$box-shadow-raised: 0px 10px 25px 0px rgba(0, 0, 0, 0.3);
$box-shadow: 0 1px 15px 1px rgba(39, 39, 39, 0.1);
$sidebar-box-shadow: 0px 2px 22px 0 rgba(0, 0, 0, 0.2),
  0px 2px 30px 0 rgba(0, 0, 0, 0.35);

$orange-color: #f96332 !default;

// Sidebar variables
$sidebar-width: calc(100% - 260px) !default;
$sidebar-mini-width: calc(100% - 80px) !default;

$opacity-b-1: rgba(0, 0, 0, 0.5) !default;
$opacity-b-2: rgba(0, 0, 0, 0.5) !default;

$db-color-25: #000000;
$db-color-25-text: #ffffff;

$db-color-24: #a47ee0;
$db-color-24-text: #58008e;

$db-color-23: #390096;
$db-color-23-text: #e0e0e0;

$db-color-22: #a84501;
$db-color-22-text: #350500;

$db-color-21: #f16302;
$db-color-21-text: #fff5f5;

$db-color-20: #f08338;
$db-color-20-text: #602000;

$db-color-19: #5c1a2a;
$db-color-19-text: #f4f4f4;

$db-color-18: #6f2210;
$db-color-18-text: #ffe8e3;
// f1f0eb
$db-color-17: #a50107;
$db-color-17-text: #120001;

$db-color-16: #be0d13;
$db-color-16-text: #ffdedf;

$db-color-15: #d52025;
$db-color-15-text: #121212;

$db-color-14:  #e42114;
$db-color-14-text: #ffe7e7;

$db-color-13:  #f13c20;
$db-color-13-text: #373737;

$db-color-12:#3f4c7d;
$db-color-12-text: #f0f3ff;

$db-color-11: #1f3996;
$db-color-11-text: #bbbbbb;

$db-color-10: #084766;
$db-color-10-text: #e5f2f9;

$db-color-9: #2e6582;
$db-color-9-text: #17242b;

$db-color-8: #007fbd;
$db-color-8-text: #e1f5ff;

$db-color-7: #59bff1;
$db-color-7-text: #00698a;

$db-color-6: #c3df89;
$db-color-6-text: #4c4c4c;

$db-color-5: #5e740f;
$db-color-5-text: #efefef;

$db-color-4: #45543f;
$db-color-4-text: #efefef;

$db-color-3: #5b644d;
$db-color-3-text: #1f1f1f;

$db-color-2: #d19e00;
$db-color-2-text: #fff4f4;

$db-color-1: #f7cf54;
$db-color-1-text: #7c3a00;

$db-color-0: #ffffff;
$db-color-0-text: #000000;

$filter-color-12: rgb(0, 0, 0);
$filter-color-11: rgb(22, 22, 22);
$filter-color-10: rgb(44, 44, 44);
$filter-color-9: rgb(66, 66, 66);
$filter-color-8: rgb(88, 88, 88);
$filter-color-7: rgb(110, 110, 110);
$filter-color-6: rgb(132, 132, 132);
$filter-color-5: rgb(154, 154, 154);
$filter-color-4: rgb(176, 176, 176);
$filter-color-3: rgb(198, 198, 198);
$filter-color-2: rgb(222, 222, 222);
$filter-color-1: rgb(255, 255, 255);

$db-color-text-light: rgb(234, 234, 234);
$db-color-text-medium: rgb(134, 134, 134);
$db-color-text-dark: rgb(35, 35, 35);
