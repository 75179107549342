// This file has been autogenerated by grunt task lessToSass. Any changes will be overwritten.
.alert {
  border: 0;
  color: $white-color;
  border-radius: $border-radius-small;
  color: $white-color;
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;

  .alert-link {
    color: $white-color;
    opacity: 0.8;
  }

  &.alert-success {
    background-color: $success-color-alert;
  }

  &.alert-danger {
    background-color: $danger-color-alert;
  }

  &.alert-warning {
    background-color: $warning-color-alert;
  }

  &.alert-info {
    background-color: $info-color-alert;
  }

  &.alert-primary {
    background-color: $primary-color-alert;
  }

  .alert-icon {
    display: block;
    float: left;
    margin-right: 15px;
    margin-top: -1px;
  }

  strong {
    text-transform: uppercase;
    font-size: 12px;
  }

  i.fa,
  i.environmentqry-icon {
    font-size: 20px;
  }

  .close {
    color: $white-color;
    opacity: 0.9;
    text-shadow: none;
    line-height: 0;
    outline: 0;
    display: inline-block;
    cursor: pointer;
  }
}
