.card-with-icon {
  max-width: 350px;
  margin: 0 auto;
  text-align: center;
  display: block;

  ul {
    list-style: none;
    padding: 0;
    max-width: 240px;
    margin: 10px auto;
    li {
      color: #888;
      text-align: center;
      padding: 1px 0;
      border-bottom: 1px solid hsla(0, 0%, 53.3%, 0.3);
    }
  }

  .card-category {
    padding-top: 1.25em;
    text-transform: capitalize;
    font-weight: 400;
    color: #888;
    font-size: 1.2em !important;
  }

  .card-icon {
    padding: 5px 0 0;
  }

  .card-icon i {
    font-size: 30px;
    color: #888;
    margin: 0 auto;
    padding: 14px;
    border-radius: 50%;
    box-shadow: 0 9px 35px -6px rgba(0, 0, 0, 0.3);
    background-color: #fff;
    position: relative;
  }

  .icon-primary i {
    box-shadow: 0 9px 30px -6px #f96332;
    color: #f96332;
  }
  .icon-warning i {
    box-shadow: 0 9px 30px -6px #ffb236;
    color: #ffb236;
  }
  .icon-danger i {
    box-shadow: 0 9px 30px -6px #ff3636;
    color: #ff3636;
  }

  &.card-plain, &.card-environment {
    .card-title {
      color: #fff;
    }

    ul li {
      color: #efefef;
    }
    .card-category {
      color: #ececec;
    }
  }

  .card-header {
    margin: 0 20px;
    padding: 30px 0;
  }

  .card-title {
    margin-top: 15px;
    margin-bottom: 15px;
    color: #000;
  }

  .card-footer {
    margin-bottom: 10px;
    margin-top: 24px;
    padding: 24px 0;
  }

  .card-body {
    padding-top: 0px;
    padding-bottom: 0px;
    min-height: auto;
  }

  .form-check,
  .form-check.form-check-radio {
    margin-top: 20px;

    label {
      margin-left: 11px;
      color: initial;
      padding-left: 40px;
    }
  }

  .card-description {
    margin-top: 15px;
    margin-bottom: 20px;
  }

  .social-line {
    margin-top: 20px;
    text-align: center;

    .btn.btn-icon,
    .btn.btn-icon .btn-icon {
      margin-left: 5px;
      margin-right: 5px;
      margin-top: 0;
      margin-bottom: 0;
      box-shadow: 0px 5px 50px 0px rgba(0, 0, 0, 0.2);
    }
  }
}

.card-stats {
  box-shadow: 0px 1px 15px 1px rgba(39, 39, 39, 0.1);
  .card-icon {
    padding: 5px 0 0;
  }

  .card-icon i {
    font-size: 25px;
    color: #888;
    margin: 0 auto;
    padding: 18px;
    border-radius: 50%;
    box-shadow: 0 9px 35px -6px rgba(0, 0, 0, 0.3);
    background-color: #fff;
    position: relative;
  }

  .icon-primary i {
    box-shadow: 0 9px 30px -6px #f96332;
    color: #f96332;
  }
  .icon-warning i {
    box-shadow: 0 9px 30px -6px #ffb236;
    color: #ffb236;
  }
  .icon-danger i {
    box-shadow: 0 9px 30px -6px #ff3636;
    color: #ff3636;
  }
  .nav-link {
    padding: 0;
  }
}
