// This file has been autogenerated by grunt task lessToSass. Any changes will be overwritten.
.environment-item-container {
  & + .environment-item-container,
  & ~ .environment-item-container {
    //margin-top: $margin-mini-vertical;
  }
  padding: 2px 0;
  position: relative;
  .environment-item-badge {
    color: $medium-gray;
    font-size: $font-size-xsmall;
    margin-right: 15px;
    left: 0;
    text-align: left;
    display: inline-block;
    width: 100%;
    margin-top: -10px;
    .name {
      background: white;
      display: block;
      color: $dark-gray;
      font-weight: 600;
      width: fit-content;
      padding-right: 10px;
    }
  }
  .environment-item-desc {
    font-style: italic;
    color: $medium-gray;
    text-align: left;
    display: block;
  }

  .progress {
    height: 1px;
    border-radius: 0;
    box-shadow: none;
    background: $opacity-gray-8;
    margin-top: 17px;
    font-size: 100%;

    .progress-bar {
      box-shadow: none;
      background-color: white;
    }

    .environment-item-price-value {
      position: absolute;
      right: 0;
      color: #e85700;
      background: white;
      height: 15px;
      line-height: 15px;
      padding-left: 5px;
    }
  }

  &.progress-neutral {
    .progress {
      background: rgba(255, 255, 255, 0.3);
    }

    .progress-bar {
      background: $white-bg;
    }

    .environment-item-price-value,
    .environment-item-badge {
      color: $white-color;
    }
  }

  &.progress-primary {
    .progress {
      background: $primary-color-opacity;
    }

    .progress-bar {
      background: $brand-primary;
    }

    .environment-item-price-value,
    .environment-item-badge {
      color: $brand-primary;
    }
  }

  &.progress-info {
    .progress {
      background: $info-color-opacity;
    }

    .progress-bar {
      background: $brand-info;
    }

    .environment-item-price-value,
    .environment-item-badge {
      color: $brand-info;
    }
  }

  &.progress-success {
    .progress {
      background: $success-color-opacity;
    }

    .progress-bar {
      background: $brand-success;
    }

    .environment-item-price-value,
    .environment-item-badge {
      color: $brand-success;
    }
  }

  &.progress-warning {
    .progress {
      background: $warning-color-opacity;
    }

    .progress-bar {
      background: $brand-warning;
    }

    .environment-item-price-value,
    .environment-item-badge {
      color: $brand-warning;
    }
  }

  &.progress-danger {
    .progress {
      background: $danger-color-opacity;
    }

    .progress-bar {
      background: $brand-danger;
    }

    .environment-item-price-value,
    .environment-item-badge {
      color: $brand-danger;
    }
  }

  &.dark {
    .environment-item-badge {
      color: #e8e8e8;
      background: $black-color;
      .name {
        color: #e8e8e8;
        font-weight: 600;
      }
    }
    .environment-item-desc {
      color: #e8e8e8;
    }

    .progress {
      background: $opacity-gray-3;

      .progress-bar {
        background: $black-color;
      }

      .environment-item-price-value {
        color: #e24900;
        background: $black-color;
      }
    }
  }
}
