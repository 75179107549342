.clear-filter[filter-color='main'] {
  background: linear-gradient(
    0deg,
    rgba($black-color, 0.6),
    rgba(0, 0, 0, 0.95)
  );
  color: $white-color;
  height: 100vh;
}
.section-nucleo-icons .icons-container i:nth-child(6) {
  color: $info-color;
}
.page-header {
  &.page-header-small {
    min-height: 60vh;
    max-height: 640px;
  }
}
