@mixin navbar-responsive() {
  &.environment-on-left .navbar-translate {
    flex-direction: row-reverse;
  }

  .navbar {
    .navbar-nav {
      margin-top: 53px;
      position: relative;
      max-height: calc(100vh - 75px);
      min-height: 100%;
      overflow: auto;

      &.navbar-logo {
        top: 0;
        height: 53px;
      }
    }

    .dropdown.show .dropdown-environment,
    .dropdown .dropdown-environment {
      background-color: transparent;
      border: 0;
      transition: none;
      -webkit-box-shadow: none;
      box-shadow: none;
      width: auto;
      margin: 0 1rem;
      margin-bottom: 15px;
      padding-top: 0;
      height: auto;
      max-height: 135px;
      overflow-y: scroll;

      &:before {
        display: none;
      }
    }

    .dropdown {
      .dropdown-item {
        padding-left: 2.5rem;
      }

      .dropdown-environment {
        display: none;
      }

      &.show .dropdown-environment {
        display: block;
      }
    }

    .dropdown-environment .dropdown-item:focus,
    .dropdown-environment .dropdown-item:hover {
      color: $white-color;
      border-radius: $border-radius-small;
    }

    .navbar-translate {
      width: 100%;
      position: relative;
      display: flex;
      -ms-flex-pack: justify !important;
      justify-content: space-between !important;
      -ms-flex-align: center;
      align-items: center;
      @include transform-translate-x(0px);
      @include transition(0.5s, cubic-bezier(0.685, 0.0473, 0.346, 1));
    }

    .navbar-toggler-bar {
      display: block;
      position: relative;
      width: 22px;
      height: 1px;
      border-radius: 1px;
      background: $white-bg;

      & + .navbar-toggler-bar {
        margin-top: 7px;
      }

      &.bar2 {
        width: 17px;
        transition: width 0.2s linear;
      }
    }

    &.bg-white:not(.navbar-transparent) .navbar-toggler-bar {
      background: $default-color;
    }
  }

  // .bar1,
  // .bar2,
  // .bar3 {
  //   outline: 1px solid transparent;
  // }
  // .bar1 {
  //   top: 0px;
  //   @include bar-animation($topbar-back);
  // }
  // .bar2 {
  //   opacity: 1;
  // }
  // .bar3 {
  //   bottom: 0px;
  //   @include bar-animation($bottombar-back);
  // }
  // .toggled .bar1 {
  //   top: 6px;
  //   @include bar-animation($topbar-x);
  // }
  // .toggled .bar2 {
  //   opacity: 0;
  // }
  // .toggled .bar3 {
  //   bottom: 6px;
  //   @include bar-animation($bottombar-x);
  // }

  .top-bar {
    transform: rotate(0);
  }
  .middle-bar {
    opacity: 1;
  }
  .bottom-bar {
    transform: rotate(0);
  }

  .top-bar,
  .middle-bar,
  .bottom-bar {
    transition: all 0.2s;
  }

  .toggled {
    .top-bar {
      transform: rotate(45deg);
      transform-origin: 0%;
    }
    .bottom-bar {
      transform: rotate(-45deg);
      transform-origin: 0%;
    }
    .middle-bar {
      opacity: 0;
    }
  }

  //
  // @include topbar-x-rotation();
  // @include topbar-back-rotation();
  // @include bottombar-x-rotation();
  // @include bottombar-back-rotation();
  //
  // @-webkit-keyframes fadeIn {
  //   0% {opacity: 0;}
  //   100% {opacity: 1;}
  // }
  // @-moz-keyframes fadeIn {
  //   0% {opacity: 0;}
  //   100% {opacity: 1;}
  // }
  // @keyframes fadeIn {
  //   0% {opacity: 0;}
  //   100% {opacity: 1;}
  // }
}

@mixin navbar-collapse() {
  .navbar-collapse {
    position: fixed;
    display: block !important;
    top: 0;
    height: 100% !important;
    width: 300px;
    right: 0;
    z-index: 1032;
    visibility: visible;
    background-color: #999;
    color: #060606;
    overflow-y: visible;
    border-top: none;
    text-align: left;

    max-height: none !important;

    @include transform-translate-x(300px);
    @include transition(0.5s, cubic-bezier(0.685, 0.0473, 0.346, 1));

    .nav-link {
      color: #060606 !important;
    }

    &:before {
      background: #f9f9ff;
      color: #060606;
      display: block;
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: -1;
    }

    &.dark {
      .nav-link {
        color: #f9f9ff !important;
      }
      &:before {
        background: #060606;
        color: #f9f9ff !important;

        display: block;
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: -1;
      }
    }

    .navbar-nav:not(.navbar-logo) {
      .nav-link {
        margin: 0 1rem;
        margin-top: 0.3125rem;
      }
    }

    .dropdown-environment .dropdown-item {
      color: $white-color;
    }
  }

  @include navbar-responsive();

  [class*='navbar-expand-'] .navbar-collapse {
    width: 300px;
  }

  .wrapper {
    @include transition(0.5s, cubic-bezier(0.685, 0.0473, 0.346, 1));
  }

  #bodyClick {
    height: 100%;
    width: 100%;
    position: fixed;
    opacity: 1;
    top: 0;
    left: auto;
    right: 300px;
    content: '';
    z-index: 9999;
    overflow-x: hidden;
    background-color: transparent;
    @include transition(0.5s, cubic-bezier(0.685, 0.0473, 0.346, 1));
  }
}
