// This file has been autogenerated by grunt task lessToSass. Any changes will be overwritten.

.nav-tabs {
  border: 0;
  padding: $padding-large-vertical $padding-base-horizontal;

  .card & {
    border-top-right-radius: $border-radius-small;
    border-top-left-radius: $border-radius-small;
  }

  > .nav-item {
    > .nav-link {
      color: $primary-color;
      margin: 0;
      margin-right: 5px;
      background-color: $transparent-bg;
      border: 1px solid $transparent-bg;
      border-radius: $border-radius-large;
      font-size: $font-size-base;
      padding: $padding-large-vertical $padding-round-horizontal;
      line-height: $line-height-general;

      &:hover {
        background-color: $transparent-bg;
        cursor: pointer;
      }

      &.active {
        box-shadow: 0px 5px 35px 0px rgba(0, 0, 0, 0.3);
        background-color: $primary-button-color;
        border-radius: $btn-round-radius;
        color: $white-color;
      }

      i.environmentqry-icon {
        font-size: 14px;
        position: relative;
        top: 1px;
        margin-right: 3px;
      }
    }

    &.disabled > .nav-link,
    &.disabled > .nav-link:hover {
      color: rgba(255, 255, 255, 0.5);
    }
  }
}
