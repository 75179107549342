.card,
.modal-content {
  border: 0;
  border-radius: $border-radius-extreme;
  display: inline-block;
  position: relative;
  width: 100%;
  margin-bottom: 20px;
  //box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.2);

  .card-header {
    background-color: $transparent-bg;
    border-bottom: 0;
    background-color: transparent;
    border-radius: 0;
    border-top-right-radius: $border-radius-small;
    border-top-left-radius: $border-radius-small;
    padding: 0;
    ul {
      min-height: 3rem;
      padding-top: 15px !important;
      &.ColorDropdownSelect__dropdown {
        min-height: 30px;
      }

      border-bottom-right-radius: 0.675rem;
      border-bottom-left-radius: 0.675rem;
    }
    .home-features {
      min-height: 4.5rem;
      font-weight: bold;
      i {
        color: #999;
        font-size: 27px;
        width: 100%;
        text-align: center;
        margin-bottom: 1rem;
        font-weight: bold;
      }
      span {
        color: #999;
        font-size: 13px;
        text-transform: uppercase;
      }
    }
  }

  .card-body {
    text-align: center;
    padding: 0.75rem 1.25rem;
  }

  .card-footer {
    margin-top: 0;
    padding-top: 0;
    background-color: $transparent-bg;
    border: 0;
  }
}
