@font-face {
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(/SF-Pro-Display-Light.woff2) format('woff2'),
      url(/SF-Pro-Display-Light.woff) format('woff');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6,
      U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
      U+2212, U+2215, U+FEFF, U+FFFD;
  }

  @font-face {
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(/SF-Pro-Display-Regular.woff2) format('woff2'),
      url(/SF-Pro-Display-Regular.woff) format('woff');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6,
      U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
      U+2212, U+2215, U+FEFF, U+FFFD;
  }

  @font-face {
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(/SF-Pro-Display-Medium.woff2) format('woff2'),
      url(/SF-Pro-Display-Medium.woff) format('woff');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6,
      U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
      U+2212, U+2215, U+FEFF, U+FFFD;
  }

  @font-face {
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(/SF-Pro-Display-Semi-Bold.woff2) format('woff2'),
      url(/SF-Pro-Display-Semi-Bold.woff) format('woff');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6,
      U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
      U+2212, U+2215, U+FEFF, U+FFFD;
  }

  @font-face {
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(/SF-Pro-Display-Bold.woff2) format('woff2'),
      url(/SF-Pro-Display-Bold.woff) format('woff');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6,
      U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193,
      U+2212, U+2215, U+FEFF, U+FFFD;
  }

@import 'ui-css/variables';
@import 'ui-css/mixins';

// // Plugins CSS
@import 'ui-css/plugins/plugin-bootstrap-switch';
@import 'ui-css/plugins/plugin-nouislider';
@import 'ui-css/plugins/plugin-datepicker';

// Core CSS
@import 'ui-css/buttons';
@import 'ui-css/inputs';
@import 'ui-css/checkboxes-radio';
@import 'ui-css/progress';
@import 'ui-css/badges';
@import 'ui-css/pagination';
@import 'ui-css/typography';
@import 'ui-css/misc';
@import 'ui-css/pills';

// components
@import 'ui-css/icons';

@import 'ui-css/tabs';
@import 'ui-css/navbar';
@import 'ui-css/dropdown';
@import 'ui-css/alerts';
@import 'ui-css/images';
@import 'ui-css/popups';
@import 'ui-css/modals';
@import 'ui-css/carousel';
@import 'ui-css/footers';

// Cards
@import 'ui-css/cards';
@import 'ui-css/cards/card-plain';
@import 'ui-css/cards/card-pricing';

// example pages and sections
@import 'ui-css/example-pages';
@import 'ui-css/sections';


// React
@import 'react/react-differences';

@import 'ui-css/display';

@import 'ui-css/responsive';
