@media screen and (max-width: 991px) {
  // .sidebar-collapse .navbar-collapse:before {
  //   background-color: $bg-environment !important;
  //   background: linear-gradient($environment-color, $environment-states-color 80%);
  // }

  // .sidebar-collapse .navbar-collapse[color='color-1']:before {
  //   background-color: $db-color-1 !important;
  //   background: linear-gradient($db-color-1, $db-color-1 90%);
  // }
  //TODO ADD OTHERS
  .sidebar-collapse .bg-environment:before {
    background-color: $bg-environment !important;
    background: linear-gradient($environment-color, $environment-states-color 80%);
  }
  .navbar-nav .dropdown-environment {
    position: static !important;
  }
}

@media screen and (max-width: 768px) {
  .nav-tabs {
    padding-left: 10px;
    padding-right: 10px;
  }
}
