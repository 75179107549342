// display flex was causing errors
.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
  display: block;
}

.carousel {
  .carousel-inner {
    // box-shadow: $box-shadow-raised;
    // border-radius: $border-radius-small;
    background: none;

    &:after,
    &:before {
      display: none;
    }
  }

  .card {
    max-width: 650px;
    margin: 60px auto;
    display: block;
  }

  .carousel-caption {
    span {
      color: $white-bg;
      display: block;
    }
    .alt-text {
      font-size: 18px;
    }
    .caption {
      font-size: 15px;
    }
  }
}

// .carousel-mask {
//   display: block;
//   left: 15px;
//   right: 15px;
//   height: 100%;
//   position: absolute;
//   background: $opacity-mask;
//   z-index: 1;
//   box-shadow: 0px 5px 25px 0px rgba(117, 117, 117, 0.5);
// }

// .carousel-col {
//   margin-bottom: 30px;
// }
