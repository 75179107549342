@font-face {
  font-family: 'SF Pro Display,sans-serif';
  src: url('~assets/fonts/nucleo-outline.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/*------------------------
	base class definition
-------------------------*/

.environmentqry-icon {
  display: inline-block;
  font: normal normal normal 14px/1 'SF Pro Display,sans-serif';
  font-size: inherit;
  text-transform: none;
  /* Better Font Rendering */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 140%;
  color: $dark-gray;
}

/*------------------------
  change icon size
-------------------------*/

/*----------------------------------
  add a square/circle background
-----------------------------------*/

.environmentqry-icon.circle {
  padding: 0.33333333em;
  vertical-align: -16%;
  background-color: #eee;
}

.environmentqry-icon.circle {
  border-radius: 50%;
}

/*------------------------
  list icons
-------------------------*/

.nc-icon-ul {
  padding-left: 0;
  margin-left: 2.14285714em;
  list-style-type: none;
}

.nc-icon-ul > li {
  position: relative;
}

.nc-icon-ul > li > .environmentqry-icon {
  position: absolute;
  left: -1.57142857em;
  top: 0.14285714em;
  text-align: center;
}

.nc-icon-ul > li > .environmentqry-icon.circle {
  top: -0.19047619em;
  left: -1.9047619em;
}

.environmentqry-icon.arrows-1_minimal-left:before {
  content: '\ea3a';
}

.environmentqry-icon.arrows-1_minimal-right:before {
  content: '\ea3b';
}

