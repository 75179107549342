.card-plain {
  background: transparent;
  box-shadow: none;

  .card-body,
  .card-header {
    padding-left: 5px;
    padding-right: 5px;
  }

  .card-header:after {
    width: 100%;
    left: 0;
  }
}

.card-environment {
  background: rgba($environment-color, .7);
  box-shadow: none;

  .card-body,
  .card-header {
    padding-left: 5px;
    padding-right: 5px;
  }

  .card-header:after {
    width: 100%;
    left: 0;
  }

  ul li {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2) !important;
  }
}