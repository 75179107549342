@media (max-width: 768px) {
  .d-md-show {
    display: none !important;
  }
}

.spinner-container {
  position: fixed;
  background: $opacity-b-2;
  z-index: 10;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.inner {
  display: flex;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.invalid {
  border-color: $danger-color !important;
}

.card-lock {
  padding: 30px;
  color: #000;
}

.card-lock .card-header .qrcode {
  width: 110px;
  height: 110px;
  border-radius: 5%;
  margin-top: -85px;
  box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.3);

  i {
    font-size: 110px;
  }
}

.left-bordered {
  border-left: 6px solid $primary-border-color !important;
}

.environment-preview {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  min-height: 40vh;
  border-radius: 6px;
  color: #fff;
  .brand-logo {
    max-width: 100px;
    margin: 4vh 0;
  }
}

.card-footer {
  span {
    /* text-align: left; */
    font-size: 0.9171em;
    /* margin: 1rem; */
    display: flex;
  }
}

.btn-wide {
  width: inherit;
  min-width: 80%;
}
.btn-mid {
  width: inherit;
  min-width: 40%;
}
.environment-onboard {
  display: inline-block;
  margin-left: 5px;
  margin-top: 25px !important;
  border: 2px solid white;
  padding-bottom: 10px;
  padding-top: 4px;
  a {
    color: white;
    display: inline-block;
    padding-left: 10px;
    font-weight: bolder;
    i {
      font-size: 12px;
    }
  }
}

.profile-table {
  margin: 0 !important;
  text-align: left;
  td {
    padding: 10px 15px !important;
  }
}

.draggable-handle {
  flex: none;
  width: 7.5px;
  height: 100%;

  &::before {
    content: "";
    border-left: 4px dotted #ccc;
    display: block;
    height: 20px;
    margin: 15px 3px;
  }

  &:hover::before {
    border-color: #888;
  }
}

.environment-detail-list {
  background: #fff;
}

.change-order-cat {
  color: #005977;
  width: 100%;
  height: 100%;
  display: block;
}

.table-button,
.table-order-button {
  cursor: pointer;
  height: 100%;
  display: block;
  width: 100%;
  min-width: 35px;
  color: $primary-border-color;
  i {
    padding: 16px 0;
  }
  img {
    max-height: 72px !important;
  }
}

.table-button {
  min-width: 170px;
}

.action-item {
  cursor: pointer;
  color: $danger-color;
  width: 100%;
  height: 100%;
  display: block;
  padding: 10px;
}

.table-item-al {
  text-align: left;
}
.table > thead > tr > th {
  font-size: medium !important;
}

.haschange-true {
  background: #cccc31;
  border: 2px solid $info-color;
  padding: 5px !important;
  color: $info-color;
  &:hover,
  &:focus,
  &:active {
    background: lighten(#cccc31, 6%);
    border: 2px solid $info-states-color;
    padding: 5px !important;
    color: $info-color;
  }
}

.table-item-al {
  padding: 6px;
  margin: 6px;
  &:focus,
  &:active {
    border: 1px solid lighten(#cccc31, 16%);
    padding: 5px;
  }
}

.environment-detail-list {
  tr {
    td {
      padding: 0 !important;
    }
  }
}

.modal-title {
  font-size: 1em !important;
  padding: 0;
  margin-left: 1rem !important;
  margin-top: -6px;
}

.fileUploader {
  width: max-content;
  justify-content: center;
}
.fileContainer {
  padding: 0 !important;
  margin: 0 !important;
  box-shadow: none !important;

  .uploadIcon {
    height: 34px !important;
    padding: 0 !important;
    margin: 0 !important;
  }
  .chooseFileButton {
    background: transparent !important;
    color: $info-color !important;
    padding: 0 !important;
    margin: 0 !important;
    font-size: 17px !important;
    font-weight: 100 !important;
  }

  .deleteImage {
    background: #7e1e1e !important;
    font-size: 15px !important;
    line-height: 24px !important;
    width: 24px !important;
    height: 24px !important;
  }

  .uploadPicturesWrapper {
    margin-top: 12px;
  }

  .uploadPictureContainer {
    min-width: 300px !important;
    margin: 1% !important;
    padding: 0 !important;
    background: #edf2f6 !important;
    border: none;

    img {
      min-width: 100%;
      min-height: 100%;
    }
  }
}

.modal-content .modal-body {
  padding: 14px !important;
}

.no-edit {
  cursor: default;
  font-weight: 600;
  display: block;
  font-size: 88%;
}

.card-contributions hr,
.card-stats hr {
  margin: 5px 15px;
}

.stats-title,
.info {
  color: #fff;
}
.info {
  display: block;
  text-align: left;
  font-size: 90%;
}
.stats-title {
  text-align: right;
  margin-top: 4px;
}

.fa-cutlery::before {
  content: "\f2e7";
}

.fa-cutlery:before {
  content: "\f2e7";
}

/* Create a custom radio button */
.theme-check-input {
  position: absolute;
  top: 0;
  right: 15px;
  height: 25px;
  width: 25px;
  color: pink;
  background-color: #eee;
  border-radius: 50%;
  cursor: pointer;
}
/* Create a custom checkbox */
.theme-checkbox-input {
  position: absolute;
  top: 10px;
  right: 30px;
  height: 20px;
  width: 20px;
  color: pink;
  background-color: #eee;
  border-radius: 50%;
  cursor: pointer;
}

.show-image {
  margin-top: 6px;
  border-radius: $border-radius-small;
}

.color-table {
  width: inherit;
}

.color-table-cell {
  height: 45px;
  border: 3px solid rgb(255, 255, 255);
  cursor: pointer;

  &.selected {
    border: 4px solid $primary-button-color;
  }
}

.color-table-cell,
.environment-preview,
.page-header-image,
.container {
  &.filter-color-0 {
    background: repeating-linear-gradient(45deg, #ffffff, #cecece 10px);

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: $db-color-text-dark;
      text-transform: none;
    }
  }
  &.filter-color-1 {
    background-color: $filter-color-1;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: $db-color-text-dark;
      text-transform: none;
    }
  }
  &.filter-color-2 {
    background-color: $filter-color-2;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: $db-color-text-dark;
      text-transform: none;
    }
  }
  &.filter-color-3 {
    background-color: $filter-color-3;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: $db-color-text-dark;
      text-transform: none;
    }
  }
  &.filter-color-4 {
    background-color: $filter-color-4;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: $db-color-text-dark;
      text-transform: none;
    }
  }
  &.filter-color-5 {
    background-color: $filter-color-5;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: $db-color-text-dark;
      text-transform: none;
    }
  }
  &.filter-color-6 {
    background-color: $filter-color-6;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: $db-color-text-dark;
      text-transform: none;
    }
  }
  &.filter-color-7 {
    background-color: $filter-color-7;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: $db-color-text-light;
      text-transform: none;
    }
  }
  &.filter-color-8 {
    background-color: $filter-color-8;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: $db-color-text-light;
      text-transform: none;
    }
  }
  &.filter-color-9 {
    background-color: $filter-color-9;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: $db-color-text-light;
      text-transform: none;
    }
  }
  &.filter-color-10 {
    background-color: $filter-color-10;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: $db-color-text-light;
      text-transform: none;
    }
  }
  &.filter-color-11 {
    background-color: $filter-color-11;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: $db-color-text-light;
      text-transform: none;
    }
  }
  &.filter-color-12 {
    background-color: $filter-color-12;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: $db-color-text-light;
      text-transform: none;
    }
  }
  &.filter-color-13 {
    background: repeating-linear-gradient(135deg, #ffffff, #cecece 10px);
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: $db-color-text-light;
      text-transform: none;
    }
  }
}

.clear-filter-theme {
  position: absolute;
  left: 15px;
  right: 15px;
  height: 98%;
}

.clear-filter[filter-color="filter-color-0"],
.clear-filter-theme[filter-color="filter-color-0"] {
  background: linear-gradient(
    0deg,
    rgba($filter-color-1, 0),
    rgba($filter-color-1, 0)
  );

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $db-color-text-dark;
    text-transform: none;
  }
}

.clear-filter[filter-color="filter-color-1"],
.clear-filter-theme[filter-color="filter-color-1"] {
  background: linear-gradient(
    0deg,
    rgba($filter-color-1, 0.2),
    rgba($filter-color-1, 0.5)
  );

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $db-color-text-dark;
    text-transform: none;
  }
}

.clear-filter[filter-color="filter-color-2"],
.clear-filter-theme[filter-color="filter-color-2"] {
  background: linear-gradient(
    0deg,
    rgba($filter-color-2, 0.2),
    rgba($filter-color-2, 0.5)
  );

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $db-color-text-dark;
    text-transform: none;
  }
}

.clear-filter[filter-color="filter-color-3"],
.clear-filter-theme[filter-color="filter-color-3"] {
  background: linear-gradient(
    0deg,
    rgba($filter-color-3, 0.2),
    rgba($filter-color-3, 0.5)
  );

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $db-color-text-dark;
    text-transform: none;
  }
}
.clear-filter[filter-color="filter-color-4"],
.clear-filter-theme[filter-color="filter-color-4"] {
  background: linear-gradient(
    0deg,
    rgba($filter-color-4, 0.2),
    rgba($filter-color-4, 0.5)
  );

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $db-color-text-dark;
    text-transform: none;
  }
}
.clear-filter[filter-color="filter-color-5"],
.clear-filter-theme[filter-color="filter-color-5"] {
  background: linear-gradient(
    0deg,
    rgba($filter-color-5, 0.2),
    rgba($filter-color-5, 0.5)
  );

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $db-color-text-dark;
    text-transform: none;
  }
}
.clear-filter[filter-color="filter-color-6"],
.clear-filter-theme[filter-color="filter-color-6"] {
  background: linear-gradient(
    0deg,
    rgba($filter-color-6, 0.2),
    rgba($filter-color-6, 0.5)
  );

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $db-color-text-dark;
    text-transform: none;
  }
}
.clear-filter[filter-color="filter-color-7"],
.clear-filter-theme[filter-color="filter-color-7"] {
  background: linear-gradient(
    0deg,
    rgba($filter-color-7, 0.2),
    rgba($filter-color-7, 0.5)
  );

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $db-color-text-light;
    text-transform: none;
  }
}
.clear-filter[filter-color="filter-color-8"],
.clear-filter-theme[filter-color="filter-color-8"] {
  background: linear-gradient(
    0deg,
    rgba($filter-color-8, 0.2),
    rgba($filter-color-8, 0.5)
  );

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $db-color-text-light;
    text-transform: none;
  }
}
.clear-filter[filter-color="filter-color-9"],
.clear-filter-theme[filter-color="filter-color-9"] {
  background: linear-gradient(
    0deg,
    rgba($filter-color-9, 0.2),
    rgba($filter-color-9, 0.5)
  );

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $db-color-text-light;
    text-transform: none;
  }
}
.clear-filter[filter-color="filter-color-11"],
.clear-filter-theme[filter-color="filter-color-11"] {
  background: linear-gradient(
    0deg,
    rgba($filter-color-11, 0.2),
    rgba($filter-color-11, 0.5)
  );

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $db-color-text-light;
    text-transform: none;
  }
}
.clear-filter[filter-color="filter-color-10"],
.clear-filter-theme[filter-color="filter-color-10"] {
  background: linear-gradient(
    0deg,
    rgba($filter-color-10, 0.2),
    rgba($filter-color-10, 0.5)
  );

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $db-color-text-light;
    text-transform: none;
  }
}

.clear-filter[filter-color="filter-color-12"],
.clear-filter-theme[filter-color="filter-color-12"] {
  background: linear-gradient(
    0deg,
    rgba($filter-color-12, 0.2),
    rgba($filter-color-12, 0.5)
  );

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $db-color-text-light;
    text-transform: none;
  }
}

.clear-filter[filter-color="filter-color-13"],
.clear-filter-theme[filter-color="filter-color-13"] {
  background: linear-gradient(
    0deg,
    rgba($filter-color-12, 0),
    rgba($filter-color-12, 0)
  );

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $db-color-text-light;
    text-transform: none;
  }
}

.environment-item {
  padding: 12px 25px;
  margin: 5px;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  height: 38px;
  font-size: $font-size-xsmall;
  border-radius: 10px;
  background-color: rgba($white-color, 0.98);
  color: $black-color;
  line-height: 1.17;
  text-align: center;
}

.environment-item-wrapper {
  outline: none;
  li {
    list-style: none;
  }
}

.environment-item-wrapper.active {
  outline: none;
}

.environment-item.active {
  border: none;
  background-color: rgba($black-color, 0.98);
  color: $white-color;
  height: 40px;
}

.sticky {
  position: fixed;
  top: 50px;
  width: 125%;
  z-index: 40;
  height: 35px;
  left: -10px;
}

.environment-item-card,
.environment-item-card-large {
  border-radius: $border-radius-small !important;
  padding: 10px;
  text-align: left;

  .name {
    display: block;
    color: $dark-gray;
    font-weight: 600;
  }

  .environment-item-desc {
    font-style: italic;
    color: $medium-gray;
    text-align: left;
    max-width: 85%;
    display: block;
  }

  .environment-item-price-value {
    display: block;
    font-weight: 700;
    color: #e85700;
  }

  .environment-item-action {
    font-size: 16px;
    font-weight: 700;
    color: #8383839c;
    cursor: pointer;
    &.liked,
    &.unliked {
      color: #ffb400;
    }
  }

  &.dark {
    background-color: $black-color;
    .name {
      color: #e8e8e8;
    }

    .environment-item-desc {
      color: #fff;
    }

    .environment-item-price-value {
      color: #e24900;
    }
  }
}

.environment-item-card {
  .img {
    float: right;
    position: inherit;
    // top: 50%;
    // transform: translateY(-50%);
    border-radius: $border-radius-small !important;
  }
}

.environment-item-card-large {
  .img {
    object-fit: cover;
    width: 100%;
    //max-height: 230px;
    margin-bottom: 10px;
    min-height: 230px;
    border-radius: $border-radius-small !important;
  }
  .imgfood {
    height: 60px;
    border-radius: $border-radius-small !important;
  }
}

.environment-preview {
  .deleteBackground {
    position: absolute;
    top: -10px;
    right: 5px;
    color: #fff;
    border-radius: 50%;
    text-align: center;
    cursor: pointer;
    font-weight: bold;
    width: 30px;
    height: 30px;
    background: $primary-button-color;
    font-size: 15px;
    line-height: 24px;
    width: 24px;
    height: 24px;
  }

  .delete-logo {
    position: absolute;
    top: 6%;
    right: 40%;
    color: #fff;
    border-radius: 50%;
    text-align: center;
    cursor: pointer;
    font-weight: bold;
    width: 30px;
    height: 30px;
    background: $primary-button-color;
    font-size: 15px;
    line-height: 24px;
    width: 24px;
    height: 24px;
  }
}

// .react-loading-skeleton {
//   position: fixed;
//   top: 0;
//   bottom: 0;
//   left: 0;
//   right: 0;
//   z-index: 1;
//   opacity: 0.9;
// }

.css-dyz6n {
  position: fixed !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ReactModal__Overlay--after-open {
  z-index: 5000 !important;
}

.password {
  &__show {
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    padding: 17px;
    font-size: 90%;
  }
}

.firebaseui-country-selector {
  height: max-content !important;
  margin-top: 19px !important;
  margin-right: 10px !important;
}

.mdl-textfield__label {
  top: 22px;
}

.navbar-fixed-bottom {
  margin-bottom: 0;
  box-shadow: 10px 3px 10px 10px #e8e8e8;
  background-color: #f3f3f3;
  z-index: 1600;
  .nav-item {
    cursor: pointer;
  }
  a {
    color: $black-states-color !important;
    font-size: 75%;
  }

  &.dark {
    background-color: $black-color;
    box-shadow: 10px 3px 10px 10px$black-states-color;
    a {
      color: #f3f3f3 !important;
    }
  }
}

.custom-map-modal {
  .modal-content {
    justify-content: center;
    padding: 30px 0px;

    .modal-footer {
      justify-content: center !important;
    }
  }
}

.mimicadminpanel {
  color: $environment-color;
}

.card-login {
  border-radius: $border-radius-large;
  padding-bottom: $padding-base-horizontal;
  max-width: 400px;

  .btn-wd {
    min-width: 180px;
  }

  .logo-container {
    width: 65px;
    margin: 0 auto;
    margin-bottom: 55px;

    img {
      width: 100%;
      border-radius: 10px;
    }
  }

  .input-group:last-child {
    margin-bottom: 40px;
  }

  &.card-plain {
    @include input-coloured-bg(
      $opacity-5,
      $white-color,
      $white-color,
      $transparent-bg,
      $opacity-1,
      $opacity-2
    );

    .input-group-text,
    .form-group.no-border .input-group-text,
    .input-group.no-border .input-group-text {
      color: $opacity-8;
    }
  }
}

.myclass {
  background: linear-gradient(45deg, black 0%, black 26%, transparent 26%),
    linear-gradient(-45deg, black 0%, black 27%, transparent 27%);
}
svg {
  display: block;
  width: 100%;
  height: 55px;
}

.float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 120px;
  right: 20px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  z-index: 100;
  .fab {
    margin-top: 11px;
    font-size: 38px;
  }
}
.ScrollUpButton__Container {
  background-color: rgba(50, 50, 50, 0.5) !important;
  height: 34px !important;
  width: 36px !important;
  right: 28px !important;
}

.categorySelect {
  width: auto !important;
  display: inline;
  position: absolute;
  right: 30px;
  top: 0;
  margin-bottom: 10px;
  padding: 9px;
}

.content-left {
  text-align: left;
  .h4-seo {
    text-transform: uppercase;
    font-weight: 700;
  }
  .btn-lg {
    bottom: 20vh;
    position: absolute;
    left: 20px;
    padding: 13px 70px;
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    max-width: 300px;
  }
}
.img-qr-code {
  min-height: 200px;
}

.show-seciton .image-container img {
  max-width: 1200px;
  left: 100px;
  top: 0;
  height: 100%;
  position: absolute;
}

.show-info .icon {
  float: left;
  margin-top: 10px;
  margin-right: 15px;
  color: $dark-gray;
  font-size: 30px;
}

.center {
  margin-top: 40vh;
  display: block;
  text-align: center;
}

.card-auth {
  max-height: 80vh;
  overflow-y: auto;

  span {
    display: block;
  }
}

.mt-6 {
  margin-top: 2rem;
}

.type-checkbox-input {
  margin: 4px -5px;
  width: 16px;
  height: 16px;
}

.campaigns {
  padding-top: 35px;
  .details {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 90%;
    left: 5%;
    font-weight: bold;
    span {
      margin-top: 4px;
      display: block;
      font-size: 75%;
    }
  }

  background-color: #f9f9ff;
  color: #383838;

  &.dark {
    color: #e8e8e8;
    background-color: black;
    .environmentqry-icon {
      color: #e8e8e8;
    }
  }
}
.carousel-control-prev,
.carousel-control-next {
  width: auto;
}

.qr-content {
  .img-large {
    cursor: pointer;
    margin: 0 25px;
  }
}
